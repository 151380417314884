<template>
  <div class="loginCard">
    <div class="card">
      <div
        class="decoration"
        :style="{ 'background-image': `url(${loginBG})` }"
      >
        <div class="content">
          <h5 class="p-3 w-80 text-center">Banker Control Panel</h5>
          <i class="d-none sm-d-block flaticon-home"></i>
          <h4 class="d-none sm-d-block py-2 w-50 text-center">Login</h4>
        </div>
      </div>
      <div class="loginForm">
        <form @submit.prevent="login">
          <div class="input-wrapper">
            <label>
              <b>Username</b>
            </label>
            <input type="text" v-model="form.username" placeholder="Email" />
          </div>
          <div class="input-wrapper">
            <label>
              <b>Password</b>
            </label>
            <input
              :type="showPassword ? 'text' : 'password'"
              v-model="form.password"
              placeholder="********"
            />
          </div>
          <!-- Toggle show password -->
          <fd-checkbox
            v-model="showPassword"
            label="Show Password"
          ></fd-checkbox>
          <div class="d-flex justify-content-end mb-1">
            <router-link :to="{ name: 'ForgotPassword' }">
              Forgot Password?
            </router-link>
          </div>
          <button type="submit" class="btn main w-100 mt-3">Login</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import loginBG from "@/assets/image/login-bg.jpeg";

export default {
  name: "Login_form",
  props: {
    type: String
  },
  data() {
    return {
      form: {
        username: "",
        password: ""
      },
      showPassword: false,
      loginBG
    };
  },
  methods: {
    async login() {
      let req = await this.submitLogin();
      if (req != null) {
        await this.$store.dispatch("login/registerFcm", req);
      }
    },
    async submitLogin() {
      try {
        this.$store.commit("setIsLoading", true);
        let request = await this.$store.dispatch("login/login", {
          data: this.form
        });
        this.$store.commit("setIsLoading", false);

        return request;
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Wrong Credential",
          text: "The email or password is incorrect, please try again."
        });

        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.loginCard {
  max-width: 140vh;
  padding: 0 16px;
  height: 80%;
  width: 100%;
  .card {
    @include flex(row, center, center);
    box-shadow: 0 2px 5px rgba(black, 0.16);
    background-color: white;
    height: 100%;
    width: 100%;
    .decoration {
      @include flex(column, center, center);
      position: relative;
      max-width: calc(4 / 12 * 100%);
      flex: calc(4 / 12 * 100%);
      height: 100%;

      background-color: #7282f6;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      // Frosted Glass
      &::before {
        background-color: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(8px) saturate(100%) contrast(75%) brightness(70%);
        content: "";
        height: 100%;
        position: relative;
        width: 100%;
      }

      .content {
        @include flex(column, center, center);
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        h4 {
          color: white;
          line-height: 1.2;
        }
        h5 {
          color: #dedbff;
        }
        i {
          font-size: 48px;
          color: white;
        }
      }
    }
    .loginForm {
      max-width: calc(8 / 12 * 100%);
      flex: calc(8 / 12 * 100%);
      background-color: white;
      form {
        max-width: 532px;
        padding: 0 16px;
        margin: 0 auto;
        .image {
          margin: 0 auto 24px;
          max-width: 150px;
          width: 100%;
        }
        .input-wrapper {
          @include flex(row, flex-start, center);
          margin: 16px 0;
          padding: 0;
          label {
            flex: calc(3 / 12 * 100%);
            max-width: calc(3 / 12 * 100%);
          }
          input {
            width: 100%;
            font-size: 15px;
            padding: 4px 8px;
            border: 1px solid #bbb;
            flex: calc(9 / 12 * 100%);
            max-width: calc(9 / 12 * 100%);
          }
        }
        .btn {
          background-color: $color-main;
          padding: 8px 16px;
          font-size: 16px;
          color: white;
          border: none;
        }
      }
    }
  }

  @media #{$breakpoint-down-md} {
    max-width: 100%;
    height: 100%;
    padding: 0;
    .card {
      flex-direction: column;
      .decoration {
        flex: calc(4 / 12 * 100%);
        max-width: 100%;
        width: 100%;
      }
      .loginForm {
        @include flex(row, center, center);
        flex: calc(8 / 12 * 100%);
        max-width: 100%;
        width: 100%;
        form {
          width: 100%;
          .input-wrapper {
            flex-direction: column;
            align-items: flex-start;
            input {
              flex: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
